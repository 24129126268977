import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Section } from "../../layout";
import { LeftImage, RightImage } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A lot has improved on Trailguide lately. Community functions, offline use,
clarity and much more. Also find out about the web app technology and if
it's a real app.`}</p>
    <br />
    <h2>{`What the heck is a web app and is it a real app?`}</h2>
    <br />
    <p>{`As you probably have seen, Trailguide is no native app. That has many
advantages. It is instantly accessible by everyone with a smartphone or a
computer. It doesn’t need to be downloaded, needs no storage on the phone
and no updates. It is fast and you always have the latest version and
information.`}</p>
    <LeftImage className="mt-8" src="news/software1.jpg" mdxType="LeftImage" />
    <br />
    <p>{`It works on all kind of hardware
with a proper browser like Chrome, Firefox or Safari. Open trailguide.net in your
browser and place a link on your Homescreen. Then open Trailguide from there
and it will work, look, feel and smell like an native app.`}</p>
    <br />
    <p>{`But of course that also has disadvantages. The discoverability in app stores for
example. The big players in the software business like Google, Apple or
Microsoft push the web technology a lot.`}</p>
    <br />
    <p><strong parentName="p">{`“Progressive Web Apps”`}</strong>{`, or PWAs for short, are web apps that have similar
functions and design of a native
app but are delivered with web infrastructure. Microsoft just announced to
lift PWA’s to the next level and make them “first-class app citizens”.
Other services like Google and Apple will follow soon. That means the web
apps will also be listed and therefore findable, rateable, etc. in all app
stores.`}</p>
    <br />
    <p>{`To enable the functions of a web app, the browser has to support them.
Today Chrome and Safari work the best with Trailguide (which are used on 90%
of all mobile devices). Other browsers will follow soon.`}</p>
    <Section mdxType="Section" />
    <h2>{`What new functions were developed lately`}</h2>
    <br />
    <RightImage src="news/reviews.jpg" mdxType="RightImage" />
    <h4>{`Reviews and statistics`}</h4>
    <p>{`The reviews are a main community feature for everybody to see how the trail
really is. To separate good from bad trails and to get an average difficulty.
For trail builders and destinations to get feedback about their trails. When
the trail and the difficulty was rated, you can see statistics and the
individual reviews in the trail description. On the map, switch to the
“Review” tab and you will immediately see where good or bad trails are.
It’s a very useful information and important that everyone shares a review
of the trail you rode.`}</p>
    <Section mdxType="Section" />
    <RightImage src="news/checkins.jpg" mdxType="RightImage" />
    <h4>{`Checkins`}</h4>
    <p>{`Log your ride and report todays conditions or trail damages. See the latest
checkins in the trail description or switch to the “Checkins” tab and see the
latest checkins close to you. In your user profile you see a list of all
trails you checked in to and have an overview what you rode.`}</p>
    <Section mdxType="Section" />
    <h4>{`Maps and grayscale`}</h4>
    <RightImage src="news/mapsgrayscale.jpg" mdxType="RightImage" />
    <p>{`Depending on the country different map types are available. We decided to
change the global topographic map from the Open Cycle Map to the Thunderforest
Landscape map. The Open Cycle Map is great, but there are too many official
cycle trails in the data, which makes it look really crowded, and full of
confusing colors. The Landscape map is also based on the very good Open Street
Map database but has a cleaner look.`}</p>
    <br />
    <p>{`To be able to distinguish trails better from the map, you can activate
“Grayscale”. A very helpful function is the “Split Map”. Split the screen into
one half satellite picture and the other half to a topographic map. Now you can
check how the trails really look like and if it’s a singletrack or not.`}</p>
    <Section mdxType="Section" />
    <h4>{`Offline use`}</h4>
    <RightImage src="news/positionoffline.jpg" mdxType="RightImage" />
    <p>{`As with many other apps, you need to be connected to the internet to load new
data. There are no more roaming costs, cheap and big data volumes are
available and also the coverage improves (at the latest with 5G it should
be very good). Nevertheless, there are situations where you want to have a
secure function to navigate offline. To be honest, we don’t have that by now,
but a solution that fulfills 90% of that demand.`}</p>
    <br />
    <p>{`Before you start the tour, pan and zoom in the map to load all map tiles
you will need later. Make sure you load all zoom steps you want and also
the uphill and surroundings of the tour into your cache. Click also on the
trail to load the description and pictures. Then switch off your mobile data.`}</p>
    <br />
    <p>{`Your phone needs very little energy now, as only the GPS is switched on and
the app doesn’t run in the background when you put your phone back into your
pocket. Everything you loaded before is now cached and available offline.
The web app still knows what to do when you use it, and with the switched on
GPS you see your location on the map. Even actions like Checkins work offline
and are uploaded when you are connected to the internet again.`}</p>
    <Section mdxType="Section" />
    <RightImage src="news/trailinfobar.jpg" mdxType="RightImage" />
    <h4>{`Display of trails and info bar`}</h4>
    <p>{`We improved the overview of the trails on the map a lot. From a certain zoom
step on, the trails are visible. The trailhead marks the start of the trail
and therefore the direction is visible. Click on the trail to open the infobar.
Click on the infobar if you want to have more information and go to the
trail description.`}</p>
    <Section mdxType="Section" />
    <RightImage src="news/localservices.jpg" mdxType="RightImage" />
    <h4>{`Local services`}</h4>
    <p>{`To get information about the bike and tourism related services that are at a
location, the businesses can register on Trailguide. The users benefit from
this information, can see the businesses in the trail descriptions nearby,
and easily get information about their services, location and contact.`}</p>
    <Section mdxType="Section" />
    <h4>{`Discussion`}</h4>
    <p>{`That's it for now. We are working constantly on the web app and improve
Trailguide. When you have ideas what is missing or should be integrated,
we would really like to hear it! Write us at info{at}trailguide.net or much
better, post it in the
`}<a parentName="p" {...{
        "href": "https://www.facebook.com/groups/184610292286074"
      }}>{`Trailguide community group`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      